<!--
 * @Date: 2024-08-08 11:57:40
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-08-09 10:46:48
 * @FilePath: \zyt-mobile-frontend\src\components\navBar\index.vue
-->
<script>
export default {
  name: 'index',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    showBack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      console.log('click')
      this.$router.back()
    },
  },
}
</script>

<template>
  <div class="header-container"></div>
</template>

<style scoped lang="less">
.header-container {
  height: 1px;
  //transform: scale(1);
  //border: 1px solid red;
  width: 110%;
  margin-left: -5%;
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    z-index: -1;
    left: 0;
    height: 160px;
    width: 100%;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      #90b7ff 100%
    );
  }
}
.van-nav-bar {
  background-color: transparent;
  width: 100%;
}
</style>
