<!--
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-11 09:37:00
 * @FilePath: \zyt-mobile-frontend\src\components\swipe\index.vue
-->
<template>
  <div class="swipe">
    <van-swipe :autoplay="5000" :show-indicators="false" lazy-render>
      <van-swipe-item
        v-for="(image, index) in bannerList"
        :key="index"
        @click="onUrlTap(image)"
      >
        <img
          :src="image[imgKey]"
          alt=""
        />
        <!--          <img v-lazy="image.img" alt="" />-->
        <div class="swipe-text" v-if="showText">
          {{ image.themeName }}
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swipe, SwipeItem } from 'vant'
import { Lazyload } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload)
export default {
  props: {
    bannerList: {
      type: Array,
      default: () => [],
    },
    showText: {
      type: Boolean,
      default: false,
    },
    isIndex: {
      type: Boolean,
      default: false,
    },
    imgKey: {
      type: String,
      default: 'img',
    },
  },
  data() {
    return {}
  },
  methods: {
    onUrlTap(img) {
      this.$emit('onTap', img)
      // if (this.isIndex) {
      //   return
      // }
      // if (img.url) {
      //   this.$router.push({
      //     path: img.url,
      //   })
      // } else {
      //   this.$router.push({
      //     path: '/ruralFilm/topic/' + img.themeName,
      //   })
      // }
    },
    //获取地址上的参数
    getQuery(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
  },
}
</script>

<style lang="less" scoped>
.swipe {
  //border: 1px solid #000;

  img {
    width: 100%;
    height: 160px;
    border-radius: 5px;
  }
  &-text {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 12px;
    transform: translateY(-5px);
  }
}
</style>
